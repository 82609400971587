import { LOGIN_USER, GET_ERRORS } from "../constants/user";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import jwtDecode from "jwt-decode";
import {SERVER} from "../constants/config";


export const fetchUser = (phone, callback) => {
  const decoded = jwtDecode(localStorage.jwtToken)
  const username = decoded.username
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const params = {
    phone
  };

  // console.log(id)

  return dispatch => {
    axios
      .get(`${SERVER}/app/users/detail`, {
        headers: headers,
        params: params
      })
      .then(res => {
        callback(res.data)
      })
      .catch(err => {
        // dispatch({
        //   // type: GET_ERRORS,
        //   // payload: err.response
        // })
        console.log(err)
      })
  }
}

export const fetchAvatar = (path, callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  }

  const params = { path }

  return dispatch => {
    axios
      .get(`${SERVER}/users/avatar`, {
        headers: headers,
        params: params
      })
      .then(res => {
        callback(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export const fetchUserType = (callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  }
  return dispatch => {
    axios
      .get(`${SERVER}/users/type`,{
        headers: headers
      })
      .then(res => {
        callback(res.data)}
      )
      .catch(console.log)
  }
}

export const updateUser = (params, callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  }
  const decoded = jwtDecode(localStorage.jwtToken)
  // const username = decoded.username
  // const user_id = decoded.user_id
  const formData = new FormData()
  formData.append("id",params.userId);
  formData.append("full_name",params.userName);
  formData.append("is_active", params.active);
  formData.append("department_id", params.userDept);
  formData.append("phone", params.userPhone);
  formData.append("user_type_id", params.userType);
  formData.append("username", params.userAcc)

  // console.log(username)

  return dispatch => {
    axios
      .put(`${SERVER}/users`, formData, {
        headers: headers
      })
      .then(res => {
        callback(res.data)
      })
      .catch(console.log)
  }
}

export const insertUser = (params,callback) => {
  const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("jwtToken"),
      fingerprint: "123456",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
    }
  // const decoded = jwtDecode(localStorage.jwtToken)
  const formData = new FormData();
  formData.append("full_name", params.full_name);
  formData.append("department_id", params.department_id);
  formData.append("phone", params.phone);
  formData.append("user_type_id", params.user_type_id);

  return dispatch => {
    axios
      .post(`${SERVER}/users`,formData, {
        headers: headers
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}


export const fetchAllUser = (user_type_id,is_active,callback) =>  {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const params = {
    user_type_id,
    is_active
  }

  return dispatch => {
    axios
      .get(`${SERVER}/users`, {
        headers: headers,
        params: params

      })
      .then(res => {

        callback(res.data);
      })
      .catch(console.log);
  };
}



export const activeUser = (userId, callback) => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
  };

  const decoded = jwtDecode(localStorage.jwtToken)
  const username = decoded.username
  const formData = new FormData()
  formData.append("id", userId);
  formData.append("username", username)

  return dispatch => {
    axios
      .put(
        `${SERVER}/users/active`, formData, {
          headers: headers,
        }
      )
      .then(res => {
        callback(res.data);
        // console.log(large)
        // history.push("/");
        // return <Redirect to='/users' />
      })
      .catch(err => {
        // console.log(large)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      });
  };
};


export const deleteUser = (user_id, callback) => {
  const decoded = jwtDecode(localStorage.jwtToken)
  const username = decoded.username
  const id = user_id
  const params = {
    username, id
  }
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
  };

  return dispatch => {
    axios
      .delete(
        `${SERVER}/users`,
        {
          headers: headers,
          params: params
        }
      )
      .then(res => {
        callback(res.data);
        // console.log(large)
        // history.push("/");
        // return <Redirect to='/users' />
      })
      .catch(err => {
        // console.log(large)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      });
  };
};

export const HideUser = (user_id, callback) => {
const decoded = jwtDecode(localStorage.jwtToken)
const edit_by = decoded.username
const id = user_id
const params = {
  edit_by, id
}


const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
  Authorization: localStorage.getItem("jwtToken"),
  fingerprint: "123456",
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
};

return dispatch => {
  axios
    .put(
      `${SERVER}/users/hide`,
      {
        headers: headers,
        params: params
      }
    )
    .then(res => {
      callback(res.data);
      // console.log(large)
      // history.push("/");
      // return <Redirect to='/users' />
    })
    .catch(err => {
      // console.log(large)
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};
};


export const UnHideUser = (user_id, callback) => {
const decoded = jwtDecode(localStorage.jwtToken)
const edit_by = decoded.username
const id = user_id
const params = {
  edit_by, id
}


const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
  Authorization: localStorage.getItem("jwtToken"),
  fingerprint: "123456",
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
};

return dispatch => {
  axios
    .put(
      `${SERVER}/users/unhide`,
      {
        headers: headers,
        params: params
      }
    )
    .then(res => {
      callback(res.data);
      // console.log(large)
      // history.push("/");
      // return <Redirect to='/users' />
    })
    .catch(err => {
      // console.log(large)
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};
};

export const checkPhoneNumber = (phone, callback) => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
  };

  const params = { phone }

  return dispatch => {
    axios
      .get(`${SERVER}/users/phone`, {
        headers: headers,
        params: params
      })
      .then(res => {
        callback(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export const requestInfoUser = userData => {
  const params = {
    phone: userData.phoneNumber,
    fingerprint: userData.fingerprint
  }

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
  }

  return dispatch => {
    axios
      .get(`${SERVER}/users/info`, {
        params: params,
        headers: headers
      })
      .then(res => {
        // console.log(res.data.detail)
        if (typeof res.data.detail !== "string") {
          const { token } = res.data.detail;
          localStorage.setItem("jwtToken", token);
          localStorage.setItem("userLogin", JSON.stringify(res.data.detail));
          // console.log('set Item Ok')
          setAuthToken(token);
          // console.log('set Token Ok')
          const decoded = jwtDecode(token);
          // console.log(decoded)
          dispatch(actLoginUser(decoded));
          // console.log('decoded Ok')
          //   callback();
        } else {
          // console.log(res.data.detail)
          alert("Tài khoản hoặc mật khẩu sai gòi.");
        }
      })
      .catch(err => {
        // console.log(err.response.data.detail)
        // alert("Bạn không có quyền đăng nhập vào web admin!")
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.detail
        });
      });
  }
}

export const requestLoginUSer = userData => {
// console.log(userData)
const params = {
  username: userData.username,
  password: userData.password,
  fingerprint: userData.fingerprint
}

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
  Authorization: localStorage.getItem("jwtToken"),
  fingerprint: "123456",
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
};

return dispatch => {
  axios
    .get(`${SERVER}/user/login`, {
      headers: headers,
      params: params
    })
    .then(res => {
      console.log(res.data.detail)
      // console.log(res.data.detail)
      if (typeof res.data.detail !== "string") {
        const { token } = res.data.detail;
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("userLogin", JSON.stringify(res.data.detail));
        // console.log('set Item Ok')
        setAuthToken(token);
        // console.log('set Token Ok')
        const decoded = jwtDecode(token);
        // console.log(decoded)
        dispatch(actLoginUser(decoded));
        // console.log('decoded Ok')
        //   callback();
      } else {
        alert("Tài khoản hoặc mật khẩu sai gòi.");
      }
    })
    .catch(err => {
      console.log(err.response.data.detail)
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.detail
      });
    });
};
};




export const updateUserRole = (user_type,employee_code, callback) => {
const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
const decoded = jwtDecode(localStorage.jwtToken)
const EditBy = decoded.employee_code
const params = {
  EditBy,
  user_type,
  employee_code,
}

return dispatch => {
  axios
    .get(`${SERVER}/user/update/role`,{
      params: params,
      headers: headers
    })
    .then(res => {
      // res.data.sizePerPage = sizePerPage
      // res.data.page = page
      // console.log(res.data)
      callback(res.data);
    })
    .catch(console.log);
};

}
export const actLoginUser = decoded => {
return {
  type: LOGIN_USER,
  payload: decoded
};
};

export const insertUserPri = (dataUserPri, callback) => {
const decoded = jwtDecode(localStorage.jwtToken)
const EditBy = decoded.employee_code
const params = {
  EditBy: EditBy,
  user_type: dataUserPri.user_type,
  reason: dataUserPri.reason,
  tq_pri: dataUserPri.inline_radios_tq,
  ts_pri: dataUserPri.inline_radios_ts,
  nd_pri: dataUserPri.inline_radios_nd,
  tb_pri: dataUserPri.inline_radios_tb,
  mh_pri: dataUserPri.inline_radios_mh,
  lh_pri: dataUserPri.inline_radios_lh,
  vp_pri: dataUserPri.inline_radios_vp,
  zl_pri: dataUserPri.inline_radios_zl,
  ht_pri: dataUserPri.inline_radios_ht,
  access_app: dataUserPri.access_app
}

const isUpdatePri = dataUserPri.isUpdatePri

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
  Authorization: localStorage.getItem("jwtToken"),
  fingerprint: "123456",
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
};

return dispatch => {
  axios
    .get(`${SERVER}/user/privilege/insert`, {
      headers: headers,
      params: params
    })
    .then(res => {

      callback(res.data);
    })
    .catch(console.log);
};
};

export const registerUser = (userData, history, callback) => {
const params = {
  username: userData.username,
  password: userData.password,
  employee_code: userData.employee_code,
  fingerprint: "123456",
  //email: userData.email,
  //full_name: userData.full_name,
  //phone: userData.phone,
  user_type: userData.user_type,
  //user_position: userData.user_position,
  //employee_name: userData.employee_name,
  //ManagerCode: userData.ManagerCode,
  //DeptCode: userData.DeptCode,
  //DeptInChargeCode: userData.DeptInChargeCode,
  EmpSelected: userData.EmpSelected
}

// console.log(userData)
const decoded = jwtDecode(localStorage.jwtToken)
const EditBy = decoded.employee_code

const formData = new FormData();
formData.append("EditBy",EditBy);
formData.append("username", userData.username);
formData.append("password", userData.password);
formData.append("fingerprint", '123456');
//formData.append("email", userData.email);
//formData.append("phone", userData.phone);
formData.append("user_type", userData.user_type);
//formData.append("user_position", userData.user_position);
//formData.append("employee_name", userData.employee_name);
//formData.append("employee_code", userData.employee_code);
//formData.append("ManagerCode", userData.ManagerCode);
//formData.append("DeptCode", userData.DeptCode);
//formData.append("DeptInChargeCode", userData.DeptInChargeCode);
formData.append("EmpSelected", userData.EmpSelected);


const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
  Authorization: localStorage.getItem("jwtToken"),
  fingerprint: "123456",
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
};

// console.log(params)

return dispatch => {
  axios
    .post(
      `${SERVER}/user/register`,formData,
      {
        headers: headers,
      }
    )
    .then(res => {
      callback(res.data);
      // console.log(large)
      // history.push("/");
      // return <Redirect to='/users' />
    })
    .catch(err => {
      // console.log(large)
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};
};

export const updateUserPW = (userData, callback) => {
const decoded = jwtDecode(localStorage.jwtToken)
const updated_by = decoded.employee_code
const params = {
  password: userData.password,
  employee_code: userData.employee_code,
  updated_by: updated_by
}

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
  Authorization: localStorage.getItem("jwtToken"),
  fingerprint: "123456",
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
};

// console.log(params)

return dispatch => {
  axios
    .get(
      `${SERVER}/user/updatepw`,
      {
        headers: headers,
        params: params
      }
    )
    .then(res => {
      callback(res.data);
      // console.log(large)
      // history.push("/");
      // return <Redirect to='/users' />
    })
    .catch(err => {
      // console.log(large)
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};
};

export const UpdateUserPri = (userData, callback) => {
const decoded = jwtDecode(localStorage.jwtToken)
const updated_by = decoded.employee_code
const params = {
  employee_code: userData.employee_code,
  user_type: userData.user_type,
  position_code: userData.user_position,
  updated_by: updated_by
}

// console.log(params)

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
  Authorization: localStorage.getItem("jwtToken"),
  fingerprint: "123456",
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
};

// console.log(params)

return dispatch => {
  axios
    .get(
      `${SERVER}/user/updatepri`,
      {
        headers: headers,
        params: params
      }
    )
    .then(res => {
      callback(res.data);
      // console.log(large)
      // history.push("/");
      // return <Redirect to='/users' />
    })
    .catch(err => {
      // console.log(large)
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};
};


export const updateUserPWDetail = (userData, callback) => {
const decoded = jwtDecode(localStorage.jwtToken)
const updated_by = decoded.employee_code
const params = {
  password: userData.password,
  employee_code: userData.employee_code,
  currentpassword: userData.currentpassword,
  updated_by: updated_by
}

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
  Authorization: localStorage.getItem("jwtToken"),
  fingerprint: "123456",
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
};

// console.log(params)

return dispatch => {
  axios
    .get(
      `${SERVER}/user/updatepw/detail`,
      {
        headers: headers,
        params: params
      }
    )
    .then(res => {
      callback(res.data);
      // console.log(large)
      // history.push("/");
      // return <Redirect to='/users' />
    })
    .catch(err => {
      // console.log(err.response.data.detail)
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.detail
      });
    });
};
};



export const fetchMaxemp = callback => {
const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
  Authorization: localStorage.getItem("jwtToken"),
  fingerprint: "123456"
};

return dispatch => {
  axios
    .get(`${SERVER}/employee/fetch/max`, null, {
      headers: headers
    })
    .then(res => {
      callback(res.data);
    })
    .catch(console.log);
};
}

export const fetchAllUserPri = callback => {
const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
  Authorization: localStorage.getItem("jwtToken"),
  fingerprint: "123456"
};

return dispatch => {
  axios
    .get(`${SERVER}/user/privilege/fetch`, null, {
      headers: headers
    })
    .then(res => {

      callback(res.data);
    })
    .catch(console.log);
};
};

export const deleteUserPri = (user_privileges_id, callback) => {
const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
  Authorization: localStorage.getItem("jwtToken"),
  fingerprint: "123456"
};
const decoded = jwtDecode(localStorage.jwtToken)
const EditBy = decoded.employee_code

const params = {
  EditBy,
  user_privileges_id
}

return dispatch => {
  axios
    .get(`${SERVER}/user/privilege/delete`, {
      headers: headers,
      params: params
    })
    .then(res => {

      callback(res.data);
    })
    .catch(console.log);
};
};

export const logout = () => {
return (dispatch) => {
  localStorage.removeItem('jwtToken')
  localStorage.removeItem('userLogin')
  setAuthToken(false)
  // dispatch(actLoginUser({}));
}
}
