import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthorize } from '../../utils/isLogin'
import jwtDecode from 'jwt-decode'

const loginGuard = ({ component: Component, path }) => {

  return (
    //    <Route to={path} component={Component} />

    <Route
      to={path}
      render={routeProps => {
        // console.log(routeProps.location.pathname)
        const user = localStorage.getItem("userLogin")
        // console.log(user)
        if (user) {
          // const company = JSON.parse(user)['company']
          //console.log(company)
          // if (company === 'UEL') {
          //   const decoded = jwtDecode(localStorage.jwtToken)
          //   const pathname = routeProps.location.pathname
          //   const username = decoded.username
          //   // console.log(isAuthorize(pathname))
          //   // console.log(pathname)
          //   if (isAuthorize(pathname)) {
          //     return <Component {...routeProps} />;
          //   }
          //   else {
          //     //console.log('Tới đây')
          //     alert('Bạn không có quyền truy cập !')
          //     return <Redirect to={"/mgt/emp/" + username} />
          //   }
          // }
          const decoded = jwtDecode(localStorage.jwtToken)
            const pathname = routeProps.location.pathname
            const username = decoded.username
            // console.log(isAuthorize(pathname))
            // console.log(pathname)
            if (isAuthorize(pathname)) {
              return <Component {...routeProps} />;
            }
            else {
              //console.log('Tới đây')
              alert('Bạn không có quyền truy cập !')
              return <Redirect to={"/mgt/emp/" + username} />
            }
        }
        // console.log('Tới đây')
        // alert('Bạn không có quyền truy cập !');
        return <Redirect to={"/login"}/>
      }}
    />
  );
};

export default loginGuard;
