import { combineReducers } from "redux";
import userReducer from './user';
import errorsReducer from './errors'
import studentReducer from './student';

const rootReducer = combineReducers({
  user: userReducer,
  errors: errorsReducer,
  student: studentReducer
});

export default rootReducer;